export const getLocalStorageFn = (itemName: string): any => {
  const item = localStorage.getItem(itemName);
  return item ? JSON.parse(item) : null;
};

export const setLocalStorageFn = (itemName: string, item: any) => {
  localStorage.setItem(itemName, JSON.stringify(item));
};

export const parseAuthFn = (auth: string): any => {
  return JSON.parse(auth);
};

export const removeLocalStorageFn = (): void => {
  localStorage.clear();
};
